:root {
  --branding-color: #0c6aae;
  --widget-color: #ffffff;
}

.branded-text {
  color: var(--branding-color);
}

.widget {
  background-color: var(--widget-color);
  border-radius: 4px;
  border: solid 1px lightgray;
  padding: 25px;
}

.button-icon {
  width: 16px;
  height: 16px;
  object-fit: contain;
}

.button-icon-flipped {
  width: 16px;
  height: 16px;
  object-fit: contain;
  transform: scaleX(-1);
}

.debug {
  border: solid;
  border-width: 2px;
  border-color: blue;
}
