.centered-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icon-button {
  width: 150px;
  margin-bottom: 20px;
}
