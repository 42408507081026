.dayCheckbox {
  margin-top: 100px;
  margin-bottom: 100px;
  margin-right: 150px;
  margin-left: 80px;
}

.Login Form {
  margin: 0 auto;
  max-width: 320px;
}